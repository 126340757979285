<template>
  <div class="prose max-w-3xl">
    <div v-html="content" />
  </div>
</template>

<script>
import { storefront } from '@contimo/api/src/api';
import { mapGetters } from 'vuex';
import { SHOP } from '@/store/gettersTypes';

export default {
  data: () => ({
    content: null,
  }),

  computed: {
    ...mapGetters([SHOP]),
    slug() {
      return this.$route.params.slug;
    },
  },

  watch: {
    slug: {
      immediate: true,
      handler(newVal) {
        storefront.getPageByUid(`${this.shop.id}.${newVal}`).then(({ data }) => {
          this.content = data.htmlContent;
        });
      },
    },
  },
};
</script>
